const bankInfoDetails = {
  bank_name: 'Yesbank',
  bank_acc_name: 'Laxmi Enterprises',
  account_number: '018463400006586',
  ifsc_code: 'YESB0000184',
  branch: 'Pitampura New Delhi',
}

module.exports = {
  bank_infos() {
    let bank_infos = []
    bank_infos.push({
      title: 'common.field.bankName',
      info: bankInfoDetails.bank_name,
    })
    bank_infos.push({
      title: 'common.field.bankAccName',
      info: bankInfoDetails.bank_acc_name,
    })
    bank_infos.push({
      title: 'common.field.accNum',
      info: bankInfoDetails.account_number,
    })
    bank_infos.push({
      title: 'common.field.ifscCode',
      info: bankInfoDetails.ifsc_code,
    })
    bank_infos.push({
      title: 'common.field.branch',
      info: bankInfoDetails.branch,
    })

    return bank_infos
  },
}
